<template>
  <div class="list">
    <div
      class="page-top"
    >
      <div v-if="list.length" class="pd12">
        <video-row
          v-for="item in list"
          :key="item.id"
          :item="item"
        ></video-row>
      </div>

      <div v-else class="empty-data">暂无待接诊的病人~</div>
    </div>

    <!-- 刷新按钮 -->
    <div class="refresh">
      <a class="primary" @click="getData">刷新</a>
    </div>
  </div>
</template>

<script>
import videoRow from './components/video-row.vue';

export default {
  components: { videoRow },
  name: 'Consult',
  data() {
    return {
      list: [],
      timer: null,
    };
  },
  created() {
    this.getData();
  },
  beforeDestroy() {
    clearTimeout(this.timer);
  },
  methods: {
    getData() {
      this.$get(`/api/blade-consult/doctorWorkVideo/getWorkDetail`)
        .then(({ data }) => {
          const videoData = data || {};
          this.list = videoData.patientInfoList || [];
        })
        .finally(() => {
          clearTimeout(this.timer);
          this.timer = setTimeout(this.getData, 10 * 60 * 1000);
        });
    },
  },
};
</script>

<style lang="less" scoped>
.list-container {
  max-height: calc(100vh - 64px);
  overflow: auto;
}
.refresh {
  position: fixed;
  top: 50%;
  right: 0;
  background-color: #fff;
  border-radius: 20px 0 0 20px;
  padding: 4px 12px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);
}
.empty-data {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
